import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import { InputSwitch } from "primereact/inputswitch";
import OkDropdown from "../../common/OkDropdown";
import TitleH2 from "../../common/TitleH2";
import { Link } from "react-router-dom";
import { AD_PLATFORM_TYPE } from "../../../models/AdPlatform";
import CustomizableLinkedSwitch from "../../common/CustomizableLinkedSwitch";

const CompaniesEditPage = ({ uiStore, breadcrumbsStore, companyStore, mainStore }) => {

    let history = useHistory();

    const {
        entity,
        setEntity,
        users,
        usersPager,
        integrations,
        integrationsPager,
        setUserLinked,
        delUserLinked,
        setIntegrationLinked,
        delIntegrationLinked,
        companyType,
    } = companyStore;

    const { title } = entity;
    const { id } = useParams();
    const { client } = mainStore;

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'companies'
        });

        setIsValid((
            !!entity.title &&
            !!entity.type.code
        ));

    }, [entity]);


    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: client.title,
                link: `/clients/${client.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: 'Компании',
                link: `/clients/${client.id}/companies`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: entity.title,
                link: `/clients/${entity.clientId}/companies/${id}/detail`,
            }, 3);
            breadcrumbsStore.addItem({
                linkName: 'Редактирование',
                link: `/clients/${entity.clientId}/companies/${id}/edit`,
            }, 4);
        }
    }, [client, entity])

    useEffect(() => {
        companyStore.loadEntity(id, true);
    }, []);

    const save = () => {
        companyStore.saveEntity()
            .then(() => {
                history.push(`/clients/${companyStore.entity.clientId}/companies/${companyStore.entity.id}/detail`);
            })
    };

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'house2_gradient'}
                >
                    {title} / Редактирование
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={() => {
                                    save();
                                }}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${entity.clientId}/companies/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='name'
                                        name='name'
                                        type='text'
                                        isrequired="true"
                                        value={entity.title}
                                        onChange={(e) => {
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название компании *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    checked={entity.enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.value })
                                    }}
                                />
                                <span className={cn('switch__text')}>Активен</span>
                            </div>
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkDropdown
                                        id='defaultApplication'
                                        name='defaultApplication'
                                        value={entity.type.code}
                                        options={companyType}
                                        optionLabel="title"
                                        optionValue="code"
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({
                                                type: {
                                                    ...entity.type,
                                                    ...{
                                                        code: e.value
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                    <label htmlFor="defaultApplication">Тип *</label>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'integrations')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={integrationsPager.itemCount}
                                color='color-orange'
                                ico='chain_gradient'
                            >
                                Интеграции / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Добавить интеграцию"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/integrations/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Интеграции</th>
                                <th>Площадка</th>
                                <th>Связь с филиалом</th>
                            </tr>
                            </thead>
                            <tbody>
                            {integrations.map((item, key) => (<tr key={`${entity.clientId}_${key}`}>
                                <td>
                                    <Link to={`/clients/${entity.clientId}/integrations/${item.adplatform.id}/detail`}>
                                        {item.adplatform.title}
                                    </Link>
                                </td>
                                <td>{AD_PLATFORM_TYPE.getTitle(item.adplatform.type)}</td>
                                <td>
                                    <div className={cn('switch', 'connection')}>
                                        <CustomizableLinkedSwitch
                                            initialLinked={item.linked}
                                            item={item}
                                            switchTextOn="Связаны"
                                            switchTextOff="Нет связи"
                                            changeHandler={(e) => {
                                                if (e.linked) {
                                                    setIntegrationLinked(e.item.adplatform.id)
                                                } else {
                                                    delIntegrationLinked(e.item.adplatform.id)
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>

                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={usersPager.itemCount}
                                color='color-blue-light'
                                ico='avatar__blue_gradient'
                            >
                                Доступы пользователей / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Добавить пользователя"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${entity.clientId}/users/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Пользователи</th>
                                <th>Доступ</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((user, key) => (<tr key={`${user.employee.id}_${key}`}>
                                <td>
                                    <Link to={`/clients/${entity.clientId}/users/${user.employee.id}/detail`}>
                                        {`${user.employee.firstname} ${user.employee.lastname}`}
                                    </Link>
                                </td>
                                <td>
                                    <div className={cn('switch', 'connection')}>
                                        <CustomizableLinkedSwitch
                                            initialLinked={user.linked}
                                            item={user}
                                            switchTextOn="Открыт"
                                            switchTextOff="Закрыт"
                                            changeHandler={(e) => {
                                                if (e.linked) {
                                                    setUserLinked(e.item.employee.id)
                                                } else {
                                                    delUserLinked(e.item.employee.id)
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


CompaniesEditPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    companyStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'companyStore', 'mainStore')(observer(CompaniesEditPage));