import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import LinkedSwitch from "components/common/LinkedSwitch";
import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";
import OkInputText from "components/common/OkInputText";

const UsersEditPage = ({ mainStore, uiStore, breadcrumbsStore, clientStore, userStore }) => {

    let history = useHistory();
    const passwordInput = useRef(null);

    const { client } = mainStore;
    const { entity, setEntity, additionalEntry, additionalCompanyEntry, setLinkedBranch, delLinkedBranch, setLinkedCompany, delLinkedCompany } = userStore;
    const { firstname, lastname, email, password, enabled } = entity;
    const { clientId, id } = useParams();

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'users'
        });

        setIsValid((
            !!entity.lastname.length &&
            !!entity.firstname.length &&
            !!entity.email.length
        ));
    }, [entity]);

    useEffect(() => {
        if (client.id && entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Клиенты',
                link: '/clients',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: clientStore.entity.title,
                link: `/clients/${clientStore.entity.id}/detail`,
            }, 1);

            breadcrumbsStore.addItem({
                linkName: `Пользователи`,
                link: `/clients/${clientStore.entity.id}/users`,
            }, 2);

            breadcrumbsStore.addItem({
                linkName: 'Редактирование',
                link: `/clients/${client.id}/users/${entity.id}/edit`,
            }, 3);
        }
    }, [client, entity])

    useEffect(() => {
        userStore.resetPassword();
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)
                userStore.loadEntity(id);
            });
    }, []);

    const save = () => {
        userStore.saveEntity()
            .then(() => {
                history.push(`/clients/${client.id}/users/${userStore.entity.id}/detail`);
            })
    };

    const setPassword = () => {
        userStore.setPassword()
            .then(() => {
                passwordInput.current.select();
                document.execCommand("copy");
            })
    }

    /**
     *
     * @param {{branchId:number, linked:boolean, userId:number}}data
     */
    const linkedBranchHandler = (data) => {
        const { branch, linked, employee } = data;
        linked === true ? setLinkedBranch(employee.id, branch.id) : delLinkedBranch(employee.id, branch.id);
    }

    /**
     *
     * @param {{companyId:number, linked:boolean, userId:number}}data
     */
    const linkedCompanyHandler = (data) => {
        const { company, linked, employee } = data;
        linked === true ? setLinkedCompany(employee.id, company.id) : delLinkedCompany(employee.id, company.id);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'avatar__blue_gradient'}
                >
                    {firstname} {lastname} / Редактирование
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/users/${entity.id}/detail`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='firstname'
                                        name='firstname'
                                        type='text'
                                        value={firstname}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ firstname: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="firstname">Имя *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='lastname'
                                        name='lastname'
                                        type='text'
                                        value={lastname}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ lastname: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="lastname">Фамилия *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            {/*TODO убрать в компонент*/}
                            <div className={cn('switch', 'switch-activity')}>
                                <InputSwitch
                                    id='enabled'
                                    name='enabled'
                                    checked={enabled}
                                    onChange={(e) => {
                                        setEntity({ enabled: e.target.value });
                                    }}
                                />
                                <span className={cn('switch__text')}>{enabled ? 'Активен' : 'Не активен'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={email}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ email: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="email">E-mail *</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='password'
                                        name='password'
                                        type='text'
                                        ref={passwordInput}
                                        value={password}
                                        onChange={(e) => {
                                            setEntity({ password: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="password">Пароль</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col', 'edit-form__col_flex')}>
                            <Button
                                className={cn('btn-gen-password')}
                                icon="pi pi-cog"
                                title="Сгенерировать и скопировать"
                                onClick={setPassword}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id || ''}
                                        disabled
                                    />
                                    <label htmlFor="id">ID</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'branches')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                color='color-blue-light'
                                ico='house_gradient'
                            >
                                Доступы пользователей к филиалам / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined', 'color-lilac')}
                                label="Добавить филиал"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/branches/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Филиалы</th>
                                <th>Бренд филиала</th>
                                <th>Тип филиала</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {additionalEntry.items.map((item, key) => (
                                <tr key={key}>
                                    <td>
                                        {item.branch.title}
                                    </td>
                                    <td>{item.branch.brand}</td>
                                    <td>{item.branch.type}</td>
                                    <td>
                                        <LinkedSwitch
                                            item={item}
                                            changeHandler={linkedBranchHandler}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={cn('detail-container', 'companies')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                color='color-blue-light'
                                ico='house2_gradient'
                            >
                                Доступы пользователей к компаниям / {client.title}
                            </TitleH2>
                        </div>

                        <div className={cn('buttons-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Добавить компанию"
                                icon="pi pi-plus"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/companies/new`);
                                }}
                            />
                        </div>

                        <table>
                            <thead>
                            <tr>
                                <th>Компания</th>
                                <th>Тип</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {additionalCompanyEntry.items.map((item, key) => (
                                <tr key={key}>
                                    <td>{item.company.title}</td>
                                    <td>{item.company.type.title}</td>
                                    <td>
                                        <LinkedSwitch
                                            item={item}
                                            changeHandler={linkedCompanyHandler}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className={cn('buttons-container', 'buttons-container__position_bottom')}>
                            <div className={cn('button-container')}>
                                <Button
                                    label="Сохранить изменения"
                                    icon="pi pi-save"
                                    onClick={save}
                                    disabled={!isValid}
                                />
                            </div>
                            <div className={cn('button-container')}>
                                <Button
                                    className={cn('p-button-outlined')}
                                    label="Отмена"
                                    onClick={() => {
                                        history.push(`/clients/${client.id}/users/${entity.id}/detail`);
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

UsersEditPage.propTypes = {
    mainStore: PropTypes.any,
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    userStore: PropTypes.any,
};

export default inject('mainStore', 'uiStore', 'breadcrumbsStore', 'clientStore', 'userStore')(observer(UsersEditPage));
