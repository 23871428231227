import React, { useEffect, useState } from "react";
import { useIsMount } from "hooks";
import cn from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types";

const LinkedSwitch = ({ item, changeHandler }) => {

    const [linked, setLinked] = useState(item.linked);

    const isMount = useIsMount();

    useEffect(() => {
        !isMount && changeHandler({
            ...item,
            ...{ linked: linked }
        });
    }, [linked]);

    return (
        <div className={cn('switch', 'switch-activity')}>
            <InputSwitch
                id='enabled'
                name='enabled'
                checked={linked}
                onChange={(e) => {
                    setLinked(e.target.value)
                }}
            />
            <span className={cn('switch__text')}>{linked ? 'Связаны' : 'Не связаны'}</span>
        </div>
    );
};

LinkedSwitch.propTypes = {
    item: PropTypes.any,
    changeHandler: PropTypes.func,
};

export default LinkedSwitch;
