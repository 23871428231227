import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { linkTo } from "../../../helper";
import cn from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import TitleH1 from "components/common/TitleH1";
import TitleH2 from "components/common/TitleH2";


const UsersDetailPage = ({ mainStore, uiStore, breadcrumbsStore, clientStore, userStore, authStore }) => {

    let history = useHistory();

    const { client } = mainStore;
    const { entity, additionalEntry, additionalCompanyEntry, setEmail } = userStore;
    const { firstname, lastname, enabled, email } = entity;
    const { clientId, id } = useParams();

    useEffect(() => {
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)

                uiStore.setPageProperty({
                    page: 'clients',
                    className: 'users'
                });

                breadcrumbsStore.addItem({
                    linkName: 'Клиенты',
                    link: '/clients',
                }, 0);

                breadcrumbsStore.addItem({
                    linkName: clientStore.entity.title,
                    link: `/clients/${clientStore.entity.id}/detail`,
                }, 1);

                breadcrumbsStore.addItem({
                    linkName: `Пользователи`,
                    link: `/clients/${clientStore.entity.id}/users`,
                }, 2);

                breadcrumbsStore.addItem({
                    linkName: `${firstname} ${lastname}`,
                    link: `/clients/${client.id}/users/${entity.id}/detail`,
                }, 3);
            });

    }, [entity])


    useEffect(() => {
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)
                userStore.loadEntity(id);
            });

    }, [])

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    ico={'avatar__blue_gradient'}
                    active={enabled}
                    showBadge={true}
                >
                    {firstname} {lastname}
                </TitleH1>
            </div>

            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/users/${entity.id}/edit`);
                                }}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Вернуться к списку"
                                onClick={() => {
                                    history.push(`/clients/${client.id}/users`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='email'
                                        name='email'
                                        type='text'
                                        value={email || ''}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        disabled
                                    />
                                    <label htmlFor="email">E-mail</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='id'
                                        name='id'
                                        type='text'
                                        value={entity.id || ''}
                                        disabled
                                    />
                                    <label htmlFor="id">ID</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="LOGIN"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    authStore.authImpersonate(email).then((result) => {
                                        if (result.auth_code) {
                                            linkTo(`${process.env.REACT_APP_FRONT_URL}/externalauth/${result.auth_code}`, "_blank")
                                            //linkTo(`http://localhost:3001/externalauth/${result.auth_code}`, "_blank")
                                        }
                                    })
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'branches')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={additionalEntry.linkCount || 0}
                                color='color-blue-light'
                                ico='house_gradient'
                            >
                                Доступы пользователей к филиалам / {client.title}
                            </TitleH2>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th>Филиалы</th>
                                <th>Бренд филиала</th>
                                <th>Тип филиала</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {additionalEntry.items.map((item, key) => {
                                if(item.linked) {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                {item.branch.title}
                                            </td>
                                            <td>{item.branch.brand}</td>
                                            <td>{item.branch.type}</td>
                                            <td>
                                        <span
                                            className={cn('badge', `badge_linked-${item.linked.toString()}`)}>{item.linked ? 'Связаны' : 'Не связаны'}</span>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('table-container', 'users')}>
                        <div className={cn('title-container')}>
                            <TitleH2
                                count={additionalCompanyEntry.linkCount || 0}
                                color='color-blue-light'
                                ico='house2_gradient'
                            >
                                Доступы пользователей к компаниям / {client.title}
                            </TitleH2>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th>Компания</th>
                                <th>Тип</th>
                                <th>Связь с интеграцией</th>
                            </tr>
                            </thead>
                            <tbody>
                            {additionalCompanyEntry.items.map((item, key) => {
                                if(item.linked) {
                                    return (
                                        <tr key={key}>
                                            <td>{item.company.title}</td>
                                            <td>{item.company.type.title}</td>
                                            <td>
                                            <span
                                                className={cn('badge', `badge_linked-${item.linked.toString()}`)}>{item.linked ? 'Связаны' : 'Не связаны'}</span>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

UsersDetailPage.propTypes = {
    mainStore: PropTypes.any,
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    userStore: PropTypes.any,
    authStore: PropTypes.any,
}

export default inject('mainStore', 'uiStore', 'breadcrumbsStore', 'clientStore', 'userStore', 'authStore')(observer(UsersDetailPage));

