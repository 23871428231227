import { action, makeObservable, observable, toJS } from "mobx";
import _ from "underscore";
import { userService } from "services";
import uiStore from "./UiStore";
import CommonPageStore from "stores/CommonPageStore";

class UserStore extends CommonPageStore {
    enabled = null;

    firstname = '';

    items = [];

    entity = {
        id: null,
        lastname: '',
        firstname: '',
        email: '',
        enabled: false,
        password: '',
        clientId: null,
        role: "manager",
    };

    additionalEntry = {
        items: [],
        linkCount: null,
    };

    additionalEntryState = {
        toLink: [],
        toUnlink: [],
    };

    additionalCompanyEntry = {
        items: [],
        linkCount: null,
    };

    additionalCompanyEntryState = {
        toLink: [],
        toUnlink: [],
    };


    constructor() {
        super();
        makeObservable(this, {
                items: observable,
                entity: observable,
                additionalEntry: observable,
                additionalCompanyEntry: observable,
                setItems: action,
                setEntity: action,
                setAdditionalEntry: action,
                setAdditionalCompanyEntry: action,
                resetEntity: action,
                setPassword: action,
                resetPassword: action,
            }
        );
    }

    addEntity = () => {
        const data = this.parseData();

        uiStore.startLoading();

        return userService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getObjectFromApi()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    setLinkedBranch = (userId, id) => {
        if (!this.additionalEntryStateLinkContains(userId, id)) {
            this.additionalEntryState.toLink.push({ userId, id });
        }

        if (this.additionalEntryStateUnlinkContains(userId, id)) {
            this.additionalEntryState.toUnlink = _.without(
                this.additionalEntryState.toUnlink,
                _.findWhere(
                    this.additionalEntryState.toUnlink,
                    { userId, id },
                )
            );
        }
        return Promise.resolve();
    }

    delLinkedBranch = (userId, id) => {
        if (!this.additionalEntryStateUnlinkContains(userId, id)) {
            this.additionalEntryState.toUnlink.push({ userId, id });
        }

        if (this.additionalEntryStateLinkContains(userId, id)) {
            this.additionalEntryState.toLink = _.without(
                this.additionalEntryState.toLink,
                _.findWhere(
                    this.additionalEntryState.toLink,
                    { userId, id },
                )
            );
        }
        return Promise.resolve();
    }

    setLinkedCompany = (userId, companyId) => {
        if (!this.additionalCompanyEntryStateLinkContains(userId, companyId)) {
            this.additionalCompanyEntryState.toLink.push({ userId, companyId });
        }

        if (this.additionalCompanyEntryStateUnlinkContains(userId, companyId)) {
            this.additionalCompanyEntryState.toUnlink = _.without(
                this.additionalCompanyEntryState.toUnlink,
                _.findWhere(
                    this.additionalCompanyEntryState.toUnlink,
                    { userId, companyId },
                )
            );
        }
        return Promise.resolve();
    }

    delLinkedCompany = (userId, companyId) => {
        if (!this.additionalCompanyEntryStateUnlinkContains(userId, companyId)) {
            this.additionalCompanyEntryState.toUnlink.push({ userId, companyId });
        }

        if (this.additionalCompanyEntryStateLinkContains(userId, companyId)) {
            this.additionalCompanyEntryState.toLink = _.without(
                this.additionalCompanyEntryState.toLink,
                _.findWhere(
                    this.additionalCompanyEntryState.toLink,
                    { userId, companyId },
                )
            );
        }
        return Promise.resolve();

    }

    saveEntity = () => {
        const branchesLink = [];
        const branchesUnlink = [];

        const companiesLink = [];
        const companiesUnlink = [];


        this.additionalEntryState.toLink.forEach(({ userId, id }) => {
            if (!this.additionalEntryLinkContains(userId, id)) {
                branchesLink.push({ userId, id });
            }
        });
        this.additionalEntryState.toUnlink.forEach(({ userId, id }) => {
            if (this.additionalEntryLinkContains(userId, id)) {
                branchesUnlink.push({ userId, id });
            }
        });
        this.additionalEntryState = {
            toLink: [],
            toUnlink: [],
        };

        this.additionalCompanyEntryState.toLink.forEach(({ userId, companyId }) => {
            if (!this.additionalCompanyEntryLinkContains(userId, companyId)) {
                companiesLink.push({ userId, companyId });
            }
        });

        this.additionalCompanyEntryState.toUnlink.forEach(({ userId, companyId }) => {
            if (this.additionalCompanyEntryLinkContains(userId, companyId)) {
                companiesUnlink.push({ userId, companyId });
            }
        });

        this.additionalCompanyEntryState = {
            toLink: [],
            toUnlink: [],
        };

        const data = this.parseData();

        uiStore.startLoading();

        return userService
            .saveEntity(this.entity.id, data)
            .then(() => {
                const linkPromises = [
                    ...branchesLink.map(({ userId, id }) => userService.setLinked(userId, id)),
                    ...branchesUnlink.map(({ userId, id }) => userService.delLinked(userId, id)),
                    ...companiesLink.map(({ userId, companyId }) => userService.setCompanyLinked(userId, companyId)),
                    ...companiesUnlink.map(({ userId, companyId }) => userService.delCompanyLinked(userId, companyId)),
                ];

                if (linkPromises.length) {
                    return Promise.all(linkPromises);
                }

                return Promise.resolve();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadEntity = (id) => {
        uiStore.startLoading();
        this.additionalEntryState = {
            toLink: [],
            toUnlink: [],
        };

        this.additionalCompanyEntryState = {
            toLink: [],
            toUnlink: [],
        };

        userService
            .loadEntity(id)
            .then(({ user, userAdditionalData, companyAdditionalData }) => {
                this.setEntity(
                    user.getObjectFromApi()
                );
                this.setAdditionalEntry(
                    userAdditionalData
                );
                this.setAdditionalCompanyEntry(
                    companyAdditionalData
                )
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = (clientId) => {
        let filter = {
            clientId: clientId,
            pager: this.pager,
            sort: this.sort,
            lastname: this.lastname,
        }
        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }


        uiStore.startLoading();
        userService
            .loadItems(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setPager(result?.pager);
                this.setSort(result?.sort)
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    /**
     * @param {Array<{}>} additionalEntry
     */
    setAdditionalEntry = (additionalEntry = {}) => {
        this.additionalEntry = {
            ...this.additionalEntry,
            ...additionalEntry,
        }
    }

    /**
     * @param {Array<{}>} additionalCompanyEntry
     */
    setAdditionalCompanyEntry = (additionalCompanyEntry = {}) => {
        this.additionalCompanyEntry = {
            ...this.additionalCompanyEntry,
            ...additionalCompanyEntry,
        }
    }


    resetPassword = () => {
        this.setEntity({
            password: '',
        })
    }

    setPassword = () => {
        this.entity.password = this.getPassword();

        return Promise.resolve(this.entity.password);
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            firstname: '',
            lastname: '',
            email: '',
            enabled: false,
            password: '',
        });
        this.additionalEntryState = {
            toLink: [],
            toUnlink: [],
        };
    }

    /**
     * @return {{firstname: string, password: string, clientId: null, role: string, email: string, enabled: boolean, lastname: string}}
     * @private
     */
    parseData = () => {
        const data = {
            lastname: this.entity.lastname,
            firstname: this.entity.firstname,
            email: this.entity.email,
            enabled: this.entity.enabled,
            clientId: this.entity.clientId,
            role: this.entity.role,
        }
        const password = (this.entity.password.length !== 0) ? { password: this.entity.password } : null

        return {
            ...data,
            ...password,
        }
    }

    /**
     * @return {string}
     * @private
     */
    getPassword = () => {
        let buf = new Uint8Array(6);
        window.crypto.getRandomValues(buf);

        return btoa(String.fromCharCode.apply(null, buf));
    }

    /**
     * @param {int} userId
     * @param {int} branchId
     * @return {boolean}
     * @private
     */
    additionalEntryStateLinkContains = (userId, branchId) => {
        return this
            .additionalEntryState
            .toLink
            .findIndex((record) => record.userId === userId && record.id === branchId) > -1;
    }

    /**
     * @param {int} userId
     * @param {int} branchId
     * @return {boolean}
     * @private
     */
    additionalEntryLinkContains = (userId, branchId) => {
        return this
            .additionalEntry
            .items
            .findIndex((item) => item.branch.id === branchId && item.employee.id === userId && item.linked) > -1;
    };

    /**
     * @param {int} userId
     * @param {int} branchId
     * @return {boolean}
     * @private
     */
    additionalEntryStateUnlinkContains = (userId, branchId) => {
        return this
            .additionalEntryState
            .toUnlink
            .findIndex((record) => record.userId === userId && record.id === branchId) > -1;
    }

    /**
     * @param {int} userId
     * @param {int} companyId
     * @return {boolean}
     * @private
     */
    additionalCompanyEntryStateLinkContains = (userId, companyId) => {
        return this
            .additionalCompanyEntryState
            .toLink
            .findIndex((record) => record.userId === userId && record.id === companyId) > -1;
    }

    /**
     * @param {int} userId
     * @param {int} companyId
     * @return {boolean}
     * @private
     */
    additionalCompanyEntryLinkContains = (userId, companyId) => {
        return this
            .additionalCompanyEntry
            .items
            .findIndex((item) => item.company.id === companyId && item.employee.id === userId && item.linked) > -1;
    };

    /**
     * @param {int} userId
     * @param {int} companyId
     * @return {boolean}
     * @private
     */
    additionalCompanyEntryStateUnlinkContains = (userId, companyId) => {
        return this
            .additionalCompanyEntryState
            .toUnlink
            .findIndex((record) => record.userId === userId && record.id === companyId) > -1;
    }

}

const userStore = new UserStore();
export default userStore;
