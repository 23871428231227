import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import 'styles/activity.scss';

const GeoActivity = ({ activity }) => {

    let connected

    switch (activity) {
        case false:
            connected = {
                status: 'false',
                title: 'Выключено',
            }
            break;

        case true:
            connected = {
                status: 'true',
                title: 'Включено',
            }
            break;
        default:
            connected = {
                status: 'false',
                title: 'Неприменимо',
            }
    }
    return (
        <div className={cn(
            'activity',
            `activity_active-${connected.status}`
        )}
             title={connected.title}
        />
    );
}

GeoActivity.propTypes = {
    activity: PropTypes.bool,
}

export default GeoActivity;