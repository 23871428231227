import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import OkInputText from "../../common/OkInputText";

const ParsingAccountsDetail = ({ uiStore, breadcrumbsStore, parsingAccountsStore }) => {
    let history = useHistory();
    const { entity } = parsingAccountsStore;
    const { title, username, yandex, gis, zoon, imap, flamp, otp } = entity;

    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        if (entity.id) {
            breadcrumbsStore.addItem({
                linkName: 'Аккаунты',
                link: '/references/parsing-accounts',
            }, 0);

            breadcrumbsStore.addItem({
                linkName: title,
                link: `/references/parsing-accounts/${entity.id}/detail`,
            }, 1);
        }
    }, [entity]);

    useEffect(() => {
        parsingAccountsStore.loadEntity(id);
    }, [])

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    showBadge={true}
                    showInfo={false}
                >
                    {title}
                </TitleH1>
            </div>

            {entity && <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/references/parsing-accounts/${entity.id}/edit`);
                                }}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Вернуться к списку"
                                onClick={() => {
                                    history.push(`/references/parsing-accounts`);
                                }}
                            />
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title || ''}
                                        disabled
                                    />
                                    <label htmlFor="name">Название аккаунта парсинга</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='username'
                                        name='username'
                                        type='text'
                                        value={username || ''}
                                        disabled
                                    />
                                    <label htmlFor="username">Имя пользователя</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            &nbsp;
                        </div>
                    </div>

                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')} style={{ marginBottom: '10px', marginTop: '10px' }}>
                            Пароли доступа к площадкам
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='yandex'
                                        name='yandex'
                                        type='text'
                                        value={yandex || ''}
                                        disabled
                                    />
                                    <label htmlFor="yandex">Яндекс</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='imap'
                                        name='imap'
                                        type='text'
                                        value={imap || ''}
                                        disabled
                                    />
                                    <label htmlFor="imap">IMAP YANDEX пароль</label>
                                </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='otp'
                                        name='otp'
                                        type='text'
                                        value={otp || ''}
                                        disabled
                                    />
                                    <label htmlFor="otp">OTP Auth (из приложения)</label>
                                </span>
                            </div>

                        </div>
                        {(entity.id && otp) && <OPTAuth id={id} source={'yandex'}/>}
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='gis'
                                        name='gis'
                                        type='text'
                                        value={gis || ''}
                                        disabled
                                    />
                                    <label htmlFor="gis">2GIS</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='flamp'
                                        name='flamp'
                                        type='text'
                                        value={flamp || ''}
                                        disabled
                                    />
                                    <label htmlFor="flamp">Flamp</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText
                                        id='zoon'
                                        name='zoon'
                                        type='text'
                                        value={zoon || ''}
                                        disabled
                                    />
                                    <label htmlFor="zoon">Zoon</label>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
        </div>
    )
}


ParsingAccountsDetail.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    parsingAccountsStore: PropTypes.any,
}


const OPTAuth = inject('parsingAccountsStore')(observer(({ parsingAccountsStore, id, source }) => {


    const [delay, setDelay] = useState(10)
    const [code, setCode] = useState('')
    const [copied, setCopied] = useState(false)
    const [timeLeft, setTimeLeft] = useState(0)


    const getOTPCode = (id, source) => {

        setCode('')

        parsingAccountsStore.getOTPCode(id, source).then(result => {
            setCode(result.code)
            setDelay(result.expires_in)
            setTimeLeft(result.expires_in)
        })
    }

    useEffect(() => {
        const timer = setInterval(() => {

            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    getOTPCode(id, source)
                    return delay
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => {
                console.error('Ошибка при копировании:', err);
            });
    };

    const copyIcon = copied ? 'pi pi-check' : 'pi pi-copy';

    return (
        <>
            <div className={cn('edit-form__col')} style={{ width: '150px' }}>
                <div className="p-field">
                    <span className="p-float-label">
                        <div className="p-inputgroup">
                        <OkInputText
                            id='otp'
                            name='otp'
                            type='text'
                            value={code ? code : 'Получение'}
                            disabled
                        />
                            <Button icon={copyIcon} className="p-button-danger" onClick={copyToClipboard}/>
                        </div>
                    </span>
                </div>
            </div>
            <div className={cn('edit-form__col')}>
                {code && timeLeft && <div className={cn('timer')}>Обновится через: {timeLeft} сек</div>}
            </div>

        </>
    )

}))

OPTAuth.propTypes = {
    parsingAccountsStore: PropTypes.any,
}


export default inject('uiStore', 'breadcrumbsStore', 'parsingAccountsStore')(observer(ParsingAccountsDetail));