import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Activity from "../../common/Activity";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { reaction } from "mobx";
import _ from "underscore";
import { Dropdown } from "primereact/dropdown";
import 'styles/pages/companies.scss';
import Sortable from "../../common/Sortable";
/**
 *
 * @param uiStore
 * @param breadcrumbsStore
 * @param companyStore
 * @param clientStore
 * @param mainStore
 * @returns {JSX.Element}
 * @constructor
 */
const CompaniesPage = ({ uiStore, breadcrumbsStore, companyStore, clientStore, mainStore }) => {
    const { clientId } = useParams();
    let history = useHistory();

    useEffect(() => {
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)

                uiStore.setPageProperty({
                    page: 'clients',
                    className: 'companies'
                });

                breadcrumbsStore.addItem({
                    linkName: 'Клиенты',
                    link: '/clients',
                }, 0);

                breadcrumbsStore.addItem({
                    linkName: clientStore.entity.title,
                    link: `/clients/${clientStore.entity.id}/detail`,
                }, 1);

                breadcrumbsStore.addItem({
                    linkName: 'Компании',
                    link: `/clients/${clientStore.entity.id}/companies`,
                }, 2);
            });

    }, [])

    /** @type {Array<Company>} items */
    const { title, setTitle, items, pager, setPager, sort, setSort, resetFilter, fEnabled, setFEnabled } = companyStore;
    //const { title, setTitle, items, pager, setPager, sort, setSort, resetFilter, fEnabled, setFEnabled } = clientStore;
    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();

        companyStore.loadItems(clientId);
    }, []);

    useEffect(() => {
        const disposers = [
            reaction(
                () => [
                    companyStore.fEnabled,
                ],
                () => {
                    companyStore.loadItems();
                }
            ),
            reaction(
                () => companyStore.title,
                (value) => {
                    titleDebounceHandler(value)
                }
            ),
            reaction(
                () => companyStore.pager.number,
                () => {
                    companyStore.loadItems();
                }
            ),

            reaction(
                () => JSON.stringify(companyStore.sort),
                () => {
                    companyStore.loadItems()
                }
            )

        ];

        return () => {
            disposers.map(func => func());
        }
    }, [])


    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && clientStore.loadItems(),
        800
    );

    const sortHandler = (obj) => {
        setSort(obj);
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    ico={'house2_gradient'}
                    color={'$color-blue-light2'}
                >
                    Компании
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать компанию"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push(`/clients/${clientId}/companies/new`);
                    }}
                />
            </div>
            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')} style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>

                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText
                                placeholder="Поиск по названию компании..."
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='title'
                            >Название компании</Sortable>
                        </th>
                        <th>Тип компании</th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='created'
                            >Создан</Sortable>
                        </th>
                        <th>
                            <Sortable
                                onSort={(obj) => sortHandler(obj)}
                                sort={sort}
                                column='updated'
                            >Изменен</Sortable>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled}/>
                            </td>
                            <td>
                                <Link to={`/clients/${item.clientId}/companies/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>{item.type.title}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first}
                    rows={pager.size}
                    totalRecords={pager.itemCount}
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>
        </div>
    )
}

CompaniesPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    companyStore: PropTypes.any,
    clientStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'companyStore', 'clientStore', 'mainStore')(observer(CompaniesPage));