import cn from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import 'styles/activity.scss';

const ParserActivity = ({ activity, connected }) => {

    const [connectedStatus, setConnectedStatus] = useState({
        status: '',
        title: '',
    })

    useEffect(() => {
        if (connected === true) {
            switch (activity) {
                case 1:
                    setConnectedStatus({
                        status: 'true',
                        title: 'Авторизован',
                    })
                    break;
                case 2:
                    setConnectedStatus({
                        status: 'connecting',
                        title: 'Подключение',
                    })
                    break;
                default:
                    setConnectedStatus({
                        status: 'warning',
                        title: 'Не авторизован',
                    })
            }
        } else {
            setConnectedStatus({
                status: 'false',
                title: 'Неприменимо',
            })
        }

    }, [])

    return (
        <div className={cn(
            'activity',
            `activity_active-${connectedStatus?.status}`
        )}
             title={connectedStatus?.title}
        />
    );
}

ParserActivity.propTypes = {
    activity: PropTypes.number,
    connected: PropTypes.bool
}


export default ParserActivity;
