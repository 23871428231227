import React from 'react';
import { Route, Switch } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';

import cn from 'classnames';
import {
    BranchesPage,
    ClientsPage,
    Header,
    IntegrationsPage,
    IntegrationsDetailPage,
    IntegrationsEditPage,
    IntegrationsNewPage,
    LoginPage,
    UsersPage,
    ClientDetailPage,
    BranchesDetailPage,
    BranchesEditPage,
    BranchesNewPage,
    UsersDetailPage,
    UsersEditPage,
    UsersNewPage,
    AdministratorsPage, AdministratorsDetailPage, AdministratorsEditPage, AdministratorsNewPage,
    CompanyGroupsPage, CompanyGroupsDetailPage, CompanyGroupsEditPage, CompanyGroupsNewPage,
    BrandsPage, BrandsDetailPage, BrandsEditPage, BrandsNewPage,
    LocationsPage, LocationsDetailPage, LocationsEditPage, LocationsNewPage, ClientEditPage, ClientNewPage,
    AnalyticsActivityPage, AnalyticsServicesPage, AnalyticsParsingPage, AnalyticsReportPage,
    BranchTypesPage, BranchTypesDetailPage, BranchTypesNewPage, BranchTypesEditPage,
    CompaniesPage, CompaniesDetailPage, CompaniesEditPage, CompaniesNewPage,
    AllIntegrationsPage, AllBranchesPage, TagsPage, TagsEditPage, TagsNewPage, TagsDetailPage,
    AdditionalActionsPage,
    ParsingAccounts,
    ParsingAccountsDetail,
    ParsingAccountsEdit,
    ParsingAccountsNow,

} from 'components';



import AuthRoute from "components/common/AuthRoute";
import Message from "components/common/Message";
import Container from "components/common/Container";

import { Breadcrumbs } from 'components/breadcrumbs';
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'styles/main.scss';

function App({ authStore }) {

    return (
        <>
            <Container
                className={cn('page-container')}
            >
                <div className={cn('header-container')}>
                    <Header />
                </div>
                <div className={cn('content-container')}>
                    <div className={cn('content')}>
                        {authStore.isAuthorized &&
                        <div className={cn('breadcrumbs-container')}>
                            <Breadcrumbs />
                        </div>
                        }

{/* TODO Страницы разделить на блоки страниц/роуты */}
                        <Switch>
                            <AuthRoute path="/references/tags" exact>
                                <TagsPage />
                            </AuthRoute>

                            <AuthRoute path="/references/tags/:id/detail">
                                <TagsDetailPage />
                            </AuthRoute>

                            <AuthRoute path="/references/tags/:id/edit">
                                <TagsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/references/tags/new">
                                <TagsNewPage />
                            </AuthRoute>


                            <AuthRoute path="/analytics/services" exact>
                                <AnalyticsServicesPage />
                            </AuthRoute>
                            <AuthRoute path="/analytics/activity" exact>
                                <AnalyticsActivityPage />
                            </AuthRoute>
                            <AuthRoute path="/analytics/parsing" exact>
                                <AnalyticsParsingPage />
                            </AuthRoute>
                            <AuthRoute path="/analytics/report" exact>
                                <AnalyticsReportPage />
                            </AuthRoute>


                            <AuthRoute path="/references/brands" exact>
                                <BrandsPage />
                            </AuthRoute>
                            <AuthRoute path="/references/brands/:id/detail">
                                <BrandsDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/references/brands/:id/edit">
                                <BrandsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/references/brands/new">
                                <BrandsNewPage />
                            </AuthRoute>

                            <AuthRoute path="/clients" exact>
                                <ClientsPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:id/detail">
                                <ClientDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:id/edit">
                                <ClientEditPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/new">
                                <ClientNewPage />
                            </AuthRoute>


                            <AuthRoute path="/clients/:clientId/branches" exact>
                                <BranchesPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/branches/:id/detail">
                                <BranchesDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/branches/:id/edit">
                                <BranchesEditPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/branches/new">
                                <BranchesNewPage />
                            </AuthRoute>

                            <AuthRoute path="/clients/:clientId/companies" exact>
                                <CompaniesPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/companies/:id/detail">
                                <CompaniesDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/companies/:id/edit">
                                <CompaniesEditPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/companies/new">
                                <CompaniesNewPage />
                            </AuthRoute>


                            <AuthRoute path="/clients/:clientId/integrations" exact>
                                <IntegrationsPage/>
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/integrations/:id/detail">
                                <IntegrationsDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/integrations/:id/edit">
                                <IntegrationsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/integrations/new">
                                <IntegrationsNewPage />
                            </AuthRoute>

                            <AuthRoute path="/clients/:clientId/users" exact>
                                <UsersPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/users/:id/detail">
                                <UsersDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/users/:id/edit">
                                <UsersEditPage />
                            </AuthRoute>
                            <AuthRoute path="/clients/:clientId/users/new">
                                <UsersNewPage />
                            </AuthRoute>

                            <AuthRoute path="/clients/:clientId/additional-actions">
                                <AdditionalActionsPage />
                            </AuthRoute>




                            <AuthRoute path="/administrators" exact>
                                <AdministratorsPage />
                            </AuthRoute>
                            <AuthRoute path="/administrators/:id/detail">
                                <AdministratorsDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/administrators/:id/edit">
                                <AdministratorsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/administrators/new">
                                <AdministratorsNewPage />
                            </AuthRoute>

                            <AuthRoute path="/integration">
                                <AllIntegrationsPage exact/>
                            </AuthRoute>

                            <AuthRoute path="/branches">
                                <AllBranchesPage exact/>
                            </AuthRoute>


                            <AuthRoute path="/references/company-groups" exact>
                                <CompanyGroupsPage />
                            </AuthRoute>
                            <AuthRoute path="/references/company-groups/:id/detail">
                                <CompanyGroupsDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/references/company-groups/:id/edit">
                                <CompanyGroupsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/references/company-groups/new">
                                <CompanyGroupsNewPage />
                            </AuthRoute>

                            <AuthRoute path="/references/locations" exact>
                                <LocationsPage />
                            </AuthRoute>
                            <AuthRoute path="/references/locations/:id/detail">
                                <LocationsDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/references/locations/:id/edit">
                                <LocationsEditPage />
                            </AuthRoute>
                            <AuthRoute path="/references/locations/new">
                                <LocationsNewPage />
                            </AuthRoute>

                            <AuthRoute path="/references/branch-types" exact>
                                <BranchTypesPage />
                            </AuthRoute>
                            <AuthRoute path="/references/branch-types/:id/detail">
                                <BranchTypesDetailPage />
                            </AuthRoute>
                            <AuthRoute path="/references/branch-types/:id/edit">
                                <BranchTypesEditPage />
                            </AuthRoute>
                            <AuthRoute path="/references/branch-types/new">
                                <BranchTypesNewPage />
                            </AuthRoute>


                            <AuthRoute path="/references/parsing-accounts" exact>
                                <ParsingAccounts />
                            </AuthRoute>

                            <AuthRoute path="/references/parsing-accounts/:id/detail">
                                <ParsingAccountsDetail />
                            </AuthRoute>

                            <AuthRoute path="/references/parsing-accounts/:id/edit">
                                <ParsingAccountsEdit />
                            </AuthRoute>

                            <AuthRoute path="/references/parsing-accounts/new">
                                <ParsingAccountsNow />
                            </AuthRoute>

                            <Route path="/" exact>
                                <LoginPage />
                            </Route>

                            <Route path='/login'>
                                <LoginPage />
                            </Route>


                        </Switch>
                    </div>
                </div>
                <Message />
            </Container>

        </>
    );
}

export default inject('authStore')(observer(App));

App.propTypes = {
    authStore: PropTypes.exact({
        isAuthorized: PropTypes.bool,
    }),
}
