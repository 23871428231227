import References from "services/References";
import uiStore from "stores/UiStore";
import { api } from "core";
import { User } from "models";

class UserService extends References {
    /**
     * @param {Object} data
     * @returns {Promise<unknown>}
     */
    addEntity = (data) => {
        return api.post(`${process.env.REACT_APP_API_URL}/admin/account/users`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )
                return new User(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     *
     * @param {number} id
     * @param {Object} data
     * @returns {Promise<unknown>}
     */
    saveEntity = (id, data) => {
        return api.put(`${process.env.REACT_APP_API_URL}/admin/account/users/${id}`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                )
                return new User(result);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }

    /**
     * @param {number} id
     * @returns {Promise<T>}
     */
    loadEntity = (id) => {
        return Promise.all([
            api.get(`${process.env.REACT_APP_API_URL}/admin/account/users/${id}`),
            api.get(`${process.env.REACT_APP_API_URL}/admin/account/users/${id}/branches`),
            api.get(`${process.env.REACT_APP_API_URL}/admin/account/users/${id}/companies`)
        ])
            .then(([user, userAdditionalData, companyAdditionalData]) => {
                return {
                    user: new User(user),
                    userAdditionalData: {
                        items: userAdditionalData.items,
                        linkCount: userAdditionalData.linkCount,
                    },
                    companyAdditionalData: {
                        items: companyAdditionalData.items,
                        linkCount: companyAdditionalData.linkCount,
                    }
                }
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} userId
     * @param {int} id branch ID
     * @return {Promise}
     */
    setLinked = (userId, id) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/account/users/${userId}/branches/${id}`, {})
            .then(() => {
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} userId
     * @param {int} id branch ID
     * @return {Promise}
     */
    delLinked = (userId, id) => {
        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/account/users/${userId}/branches/${id}`)
            .then(() => {
                console.debug(`Unlinked userId: ${userId}, branchId: ${id}`);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    setCompanyLinked = (userId, companyId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/account/users/${userId}/companies/${companyId}`, {})
            .then(() => {
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    delCompanyLinked = (userId, companyId) => {
        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/account/users/${userId}/companies/${companyId}`)
            .then(() => {
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });

    }

    /**
     * @param {int} userId
     * @param {int} companyId company ID
     * @return {Promise}
     */
    setLinkedByCompany = (userId, companyId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/users/${userId}`, {})
            .then(() => {
                console.debug(`Linked userId: ${userId}, companyId: ${companyId}`);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {int} userId
     * @param {int} companyId company ID
     * @return {Promise}
     */
    delLinkedByCompany = (userId, companyId) => {
        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/users/${userId}`)
            .then(() => {
                console.debug(`Unlinked userId: ${userId}, companyId: ${companyId}`);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }


    /**
     * @param {Object} filter
     * @returns {Promise<{items: User[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    loadItems = (filter) => {
        const params = this.parseFilter(filter);

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/account/users?${params}`)
            .then(result => {
                return {
                    items: result.items.map((item) => new User(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }
}

const userService = new UserService();
export default userService;
