import React, { useEffect, useState } from "react";
import cn from "classnames";
import TitleH1 from "../../../common/TitleH1";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import analyticsService from "services/Analytics";
import { Button } from "primereact/button";
import {downloadFile} from "../../../../helper";

const AnalyticsReportPage = ({ uiStore, breadcrumbsStore }) => {

    useEffect(() => {
        uiStore.setPageProperty({ page: 'analytics', className: 'analytics' });

        breadcrumbsStore.addItem({
            linkName: 'Отчеты', link: `/analytics/report`,
        }, 0);

    }, []);

    return (<div>
        <div className={cn('header-container')}>
            <TitleH1
                color={'color-lilac'}
            >
                Аналитика / Отчеты
            </TitleH1>
        </div>
        <div>
            <DownloadsOrdersReport />
            <DownloadsUsersReport />
        </div>
    </div>)
}

AnalyticsReportPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    analyticsServicesStore: PropTypes.any,
}

const DownloadsOrdersReport = () => {

    const [loading, setLoading] = useState(false);

    return (
        <div className={cn('form-container')} style={{ paddingBottom: 0 }}>
            <div className={cn('edit-form__row')}>
                <div className={cn('edit-form__col', 'edit-form__col_title')}>
                    <h3>Выгрузка заявок за прошлый календарный месяц</h3>
                </div>
                <div className={cn('edit-form__col')} style={{ margin: "auto" }}>
                    <div className={cn('report-button__wrapper')}>
                        <Button
                            className={cn('p-button-outlined')}
                            label="Скачать"
                            icon="pi pi-download"
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                analyticsService.getXLSOrdersReport().then(result => {
                                    downloadFile(result.blob, result.filename, "text/csv")
                                })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                    .finally(()=>{
                                        setLoading(false)
                                    })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const DownloadsUsersReport = () => {
    const [loading, setLoading] = useState(false);

    return (
        <div className={cn('form-container')} style={{ paddingBottom: 0 }}>
            <div className={cn('edit-form__row')}>
                <div className={cn('edit-form__col', 'edit-form__col_title')}>
                    <h3>Выгрузка активных пользователей активных компаний</h3>
                </div>
                <div className={cn('edit-form__col')} style={{ margin: "auto" }}>
                    <div className={cn('report-button__wrapper')}>
                        <Button
                            className={cn('p-button-outlined')}
                            label="Скачать"
                            icon="pi pi-download"
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                analyticsService.getXLSUsersReport().then(result => {
                                    downloadFile(result.blob, result.filename, "text/csv")
                                })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                    .finally(()=>{
                                        setLoading(false)
                                    })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}





export default inject('uiStore', 'breadcrumbsStore')(observer(AnalyticsReportPage));

