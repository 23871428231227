import References from "./References";
import { api } from "../core";

class ParsingAccountsService extends References {

    constructor() {
        super();
    }

    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts`, data)
            .then(result => result)
            .catch(error => error);

    }

    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts/${id}`, data)
            .then(result => result)
            .catch(error => error);
    }

    loadEntity = (id) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts/${id}`)
            .then(result => result)
            .catch(error => error);

    }

    loadItems = (filter) => {
        const params = this.parseFilter(filter);
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts?${params}`)
            .then(result => {
                return {
                    items: result.items,
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch(error => error)

    }

    getOTPCode = (id, source) => {

/*

        const code = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    "code": code,
                    "expires_in": 15,
                })
            }, 2000)
        })
*/

        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts/${id}/otpauth/${source}`, {})
            .then(result => result)
            .catch(error => error);

    }

}

const parseAccountsService = new ParsingAccountsService();
export default parseAccountsService;