import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { inject, observer } from "mobx-react";
import { reaction, toJS } from "mobx";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "underscore";

import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import Activity from "components/common/Activity";
import ParserActivity from "../../common/PaserActivity";

import TitleH1 from "components/common/TitleH1";
import 'styles/pages/integrations.scss';
import OverflowLink from "../../common/OverflowLink";
import CustomizableLinkedSwitch from "../../common/CustomizableLinkedSwitch";
import { Dropdown } from "primereact/dropdown";
import GeoActivity from "../../common/GeoActivity";

/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {AdPlatformStore} adPlatformStore
 * @param {ClientStore} clientStore
 * @return {JSX.Element}
 * @constructor
 */
const AllIntegrationsPage = ({ uiStore, breadcrumbsStore, integrationStore, clientStore, mainStore }) => {
    const {
        items,
        pager,
        setPager,
        query,
        setQuery,
        fType,
        setFType,
        setFParserReviews,
        setFParserGeodata,
        setClientId,
        clientId,
        fEnabled,
        fParserReviews,
        fParserGeodata,
        setFEnabled,
        resetFilter,
        itemsBranches,
    } = integrationStore;

    const [clients, setClients] = useState([]);
    const [adTypes, setAdTypes] = useState([]);


    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();
        mainStore.setClient({})
        uiStore.setPageProperty({
            page: 'integrations',
            className: 'integrations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Интеграции',
            link: '/integrations',
        }, 0);


        clientStore.loadAllItems()
            .then(result => {
                setClients(result)
            });

        integrationStore.getAdPlatformType()
            .then(result => {
                setAdTypes(result);
            });

        integrationStore.loadItems();

    }, [])

    useEffect(() => {

        const disposers = [
            reaction(
                () => [
                    integrationStore.fType,
                    integrationStore.fEnabled,
                    integrationStore.fParserReviews,
                    integrationStore.fParserGeodata,
                ],
                () => {
                    integrationStore.loadItems();
                }
            ),
            reaction(
                () => [
                    integrationStore.clientId,
                ],
                () => {
                    integrationStore.loadItems();
                }
            ),
            reaction(
                () => integrationStore.query,
                (value) => {
                    queryDebounceHandler(value)
                }
            ),

            reaction(
                () => integrationStore.pager.number,
                () => {
                    integrationStore.loadItems();
                }
            ),
        ];
        return () => {
            disposers.map(func => func());
        }
    }, []);

    const getClientName = (clientId) => {
        return clients.find(item => item.code === clientId)
    }

    const queryDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && integrationStore.loadItems(),
        800
    );

    /** @namespace item.account */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    ico={'chain_gradient'}
                    color={'color-orange'}
                >
                    Все интеграции
                </TitleH1>
            </div>
            <div className={cn('search-container')} style={{ paddingTop: '0' }}>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fType}
                                options={[
                                    ...[{ 'name': 'Все', 'code': -1 }],
                                    ...adTypes,
                                ]}
                                onChange={(e) => {
                                    setFType(e.value)
                                }}
                                id="fType"
                                name="fType"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fType">Тип площадки</label>
                            </span>
                    </div>
                </div>
                <div className={cn('dropdown-container')} style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>

                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                value={clientId}
                                options={
                                    [
                                        ...[{ 'name': 'Все', 'code': -1 }],
                                        ...clients,
                                    ]
                                }
                                onChange={(e) => {
                                    setClientId(e.value);
                                }}
                                id="client"
                                name="client"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="client">Клиент</label>
                            </span>
                    </div>
                </div>
            </div>
            <div className={cn('search-container')} style={{ paddingTop: '0' }}>
                <div className={cn('dropdown-container')}  style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fParserReviews}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Включено', code: 1 },
                                    { name: 'Выключено', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFParserReviews(e.value)
                                }}
                                id="fParserReviews"
                                name="fParserReviews"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fParserReviews">Мониторинг</label>
                            </span>
                    </div>
                </div>
                <div className={cn('dropdown-container')}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fParserGeodata}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Включено', code: 1 },
                                    { name: 'Выключено', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFParserGeodata(e.value)
                                }}
                                id="fParserGeodata"
                                name="fParserGeodata"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fParserGeodata">Геоданные</label>
                            </span>
                    </div>
                </div>

                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText
                                placeholder="Поиск по названию или ссылке..."
                                value={query}
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>



            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>Название интеграции</th>
                        <th>Клиент</th>
                        <th>Филиалы</th>
                        <th>Площадка</th>
                        <th>Авторизация парсера</th>
                        <th>Мониторинг</th>
                        <th>Геоданные</th>
                        <th>Страница на площадке</th>
                    </tr>
                    </thead>
                    <tbody>

                    {clients.length > 0 && items.map((item, key) => (
                        <Row
                            item={item}
                            itemBranches={itemsBranches[item.id] || []}
                            client={getClientName(item.clientId)}
                            key={`${item.id}_${key}`}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first}
                    rows={pager.size}
                    totalRecords={pager.itemCount}
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>
        </div>
    )
}

AllIntegrationsPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    integrationStore: PropTypes.any,
    clientStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'integrationStore', 'clientStore', 'mainStore')(observer(AllIntegrationsPage));

/**
 * @param {AdPlatform} item
 * @param {BranchAdPlatformLink[]} itemBranches
 * @param {string} clientId
 * @return {JSX.Element}
 * @constructor
 */
const Row = ({ item, itemBranches, client }) => {
    const [show, setShow] = useState(false);
    const handleClick = () => {
        setShow(!show);
    }

    return (
        <>
            <tr>
                <td>{item.id}</td>
                <td className={cn('activity-data')}>
                    <Activity activity={item.enabled}/>
                </td>
                <td>
                    <Link to={`/clients/${item.clientId}/integrations/${item.id}/detail`}>{item.title}</Link>
                </td>
                <td>
                    <Link to={`/clients/${item.clientId}/detail`}>{client?.name}</Link>
                </td>
                <td className={cn('branches-data')}>
                    <BtnShowBranches
                        show={show}
                        handleClick={handleClick}
                        branchesCount={itemBranches.length}
                    />
                </td>
                <td>{item.type.name}</td>
                <td className={cn('activity-data')}>
                    <ParserActivity activity={item.parser.auth.status} connected={item.parser.connected}/>
                </td>
                <td className={cn('activity-data')}>
                    <GeoActivity activity={item.parser.connections.reviews}/>
                </td>
                <td className={cn('activity-data')}>
                    <GeoActivity activity={item.parser.connections.geodata}/>
                </td>
                <td>
                    {item.accountUrl && <OverflowLink link={item.accountUrl}/>}
                </td>
            </tr>
            <BranchList
                items={itemBranches}
                show={show}
                clientId={item.clientId}
                integrationTitle={item.title}
                integrationId={item.id}
            />
        </>
    );
}

Row.propTypes = {
    item: PropTypes.object,
    itemBranches: PropTypes.array,
    client: PropTypes.object,
}

/**
 * @param {BranchAdPlatformLink[]} items
 * @param {boolean} show
 * @param {int} clientId
 * @param {string} integrationTitle
 * @param {int} integrationId
 * @return {JSX.Element}
 * @constructor
 */
const BranchList = ({ items, show, clientId, integrationTitle, integrationId }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            {show && <>
                {items.map((item, key) => (
                    <tr className={cn('branches-list')} key={key}>
                        <td colSpan={4}>&nbsp;</td>
                        <td colSpan={4}>
                            <Link
                                to={`/clients/${clientId}/branches/${item.branch.id}/detail`}
                            >{item.branch.title}</Link>
                        </td>
                    </tr>
                ))}
                <tr className={cn('branches-list')}>
                    <td colSpan={4}>&nbsp;</td>
                    <td colSpan={1}>
                        <div
                            className={cn('btn-open-modal')}
                            onClick={() => setOpenModal(true)}
                        >
                            <span className={cn('ico', 'chain', 'btn-open-modal__ico')}/>
                        </div>
                    </td>
                    <td colSpan={3}>&nbsp;</td>
                </tr>
            </>}
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                clientId={clientId}
                integrationTitle={integrationTitle}
                integrationId={integrationId}
            />

        </>
    );
};

BranchList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    show: PropTypes.bool,
    clientId: PropTypes.number,
    integrationTitle: PropTypes.string,
    integrationId: PropTypes.number,
};

/**
 * @param {bool} openModal
 * @param {function} setOpenModal
 * @param {string} clientId
 * @param {string} integrationTitle
 * @param {AdPlatformStore} adPlatformStore
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = inject('adPlatformStore')(observer(({
                                                      openModal,
                                                      setOpenModal,
                                                      clientId,
                                                      integrationTitle,
                                                      integrationId,
                                                      adPlatformStore,
                                                  }) => {
    const history = useHistory();
    const {
        modalBranches,
        setModalBranchLinked,
        delModalBranchLinked,
    } = adPlatformStore;

    const save = () => {
        adPlatformStore
            .saveModalBranches(integrationId)
            .then(() => {
                setOpenModal(false);
                adPlatformStore.loadItems(clientId);
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        if (openModal && integrationId) {
            adPlatformStore.loadModalBranches(integrationId);
        }
    }, [openModal, integrationId]);

    return (
        <Dialog
            visible={openModal}
            showHeader={false}
            closable={false}
            style={{ width: '1300px' }}
            onHide={() => {
                setOpenModal(false)
            }}
        >
            <div className={cn('page-container', 'integrations', 'modal')}>
                <div className={cn('content-container')}>
                    <div className={cn('content')}>
                        <div
                            className={cn('pi', 'pi-times', 'btn-close-modal')}
                            onClick={() => {
                                setOpenModal(false)
                            }}
                        />
                        <div className={cn('header-container')}>
                            <TitleH1>
                                Управление связями / {integrationTitle}
                            </TitleH1>
                        </div>
                        <div className={cn('table-container')}>
                            <table>
                                <thead>
                                <tr>
                                    <th>Филиал</th>
                                    <th>Связь с интеграцией</th>
                                </tr>
                                </thead>
                                <tbody>
                                {modalBranches.map((item, key) => (<tr key={key}>
                                    <td>{item.branch.title}</td>
                                    <td>
                                        <CustomizableLinkedSwitch
                                            initialLinked={item.linked}
                                            item={item}
                                            switchTextOn="Связаны"
                                            switchTextOff="Нет связи"
                                            changeHandler={(e) => {
                                                if (e.linked) {
                                                    setModalBranchLinked(e.item.branch.id);
                                                } else {
                                                    delModalBranchLinked(e.item.branch.id);
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                        <div className={cn('form-container')}>
                            <div className={cn('button-container')}>
                                <Button
                                    label="Сохранить изменения"
                                    icon="pi pi-save"
                                    onClick={() => {
                                        save();
                                    }}
                                />
                            </div>
                            <div className={cn('button-container')}>
                                <Button
                                    onClick={() => {
                                        setOpenModal(false)
                                    }}
                                    className={cn('p-button-outlined')}
                                    label="Отмена"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}));

Modal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    clientId: PropTypes.number,
    integrationTitle: PropTypes.string,
    integrationId: PropTypes.number,
    adPlatformStore: PropTypes.any,
}

const BtnShowBranches = ({ show, handleClick, branchesCount }) => {
    const btnState = show ? 'open' : 'closed';

    return (
        <div
            className={cn('btn-show-branches', `btn-show-branches_${btnState}`)}
            onClick={() => {
                handleClick()
            }}
        >
            <span className={cn('ico', 'house')}/>
            <span className={cn('btn-show-branches__text')}>{branchesCount}</span>

            <span className={cn('pi', 'pi-chevron-up')}/>
            <span className={cn('pi', 'pi-chevron-down')}/>

        </div>
    );
};

BtnShowBranches.propTypes = {
    show: PropTypes.bool,
    handleClick: PropTypes.func,
    branchesCount: PropTypes.number,
}
