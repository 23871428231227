import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router";
import cn from "classnames";
import TitleH1 from "../../common/TitleH1";
import { Button } from "primereact/button";
import OkInputText from "../../common/OkInputText";
import FieldMessageError from "./FieldMessageError";

const ParsingAccountsNow = ({ uiStore, breadcrumbsStore, parsingAccountsStore }) => {

    let history = useHistory();

    const { entity, setEntity, resetEntity, resetFilter } = parsingAccountsStore;
    const { title, username, yandex, gis, zoon, imap, flamp, otp } = entity;

    const [isValid, setIsValid] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        resetEntity()
    }, []);

    useEffect(() => {

        resetFilter()

        uiStore.setPageProperty({
            page: 'references',
            className: 'locations'
        });

        breadcrumbsStore.addItem({
            linkName: 'Аккаунты',
            link: '/references/parsing-accounts',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: 'Добавить значение',
            link: '/references/parsing-accounts/new',
        }, 1);

//TODO доработать обработку ошибок и валидацию!!!
        setIsValid(
            !!entity.title?.length
/*
                !!entity.username.length &&
                !!entity.yandex.length &&
                !!entity.gis.length &&
                !!entity.flamp.length &&
                !!entity.zoon.length
*/
        );


    }, [entity]);

    const save = () => {
        setErrors([]);
        parsingAccountsStore.addEntity()
            .then((result) => {
                if (result.id) {
                    history.push(`/references/parsing-accounts/${result.id}/detail`);
                }
            })
            .catch((error) => {
//TODO доработать обработку ошибок и валидацию!!!

                switch (error.statusCode) {
                    case 422:
                        setErrors(error.error.data)

                        uiStore.setMessage(
                            {
                                sticky: false,
                                life: 2000,
                                severity: 'error',
                                summary: "Ошибка",
                                detail: error.error.description,
                            }
                        );
                        break;
                    case 500:
                        uiStore.setMessage(
                            {
                                sticky: false,
                                life: 2000,
                                severity: 'error',
                                summary: 'Внимание',
                                detail: error.error.description
                            }
                        );
                        break
                    default:
                        uiStore.setMessage(
                            {
                                sticky: false,
                                life: 2000,
                                severity: 'error',
                                summary: 'Внимание',
                                detail: 'Неизвестная ошибка'
                            }
                        );

                }
            });
    }

    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                >
                    {title ? title : 'Новый аккаунт'}
                </TitleH1>
            </div>
            <div className={cn('form-container')}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                label="Сохранить изменения"
                                icon="pi pi-save"
                                onClick={save}
                                disabled={!isValid}
                            />
                        </div>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Отмена"
                                onClick={() => {
                                    history.push(`/references/parsing-accounts`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='title'
                                        name='title'
                                        type='text'
                                        value={title || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            // noinspection JSUnresolvedVariable
                                            setEntity({ title: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="name">Название аккаунта парсинга *</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'title'}/> }
                            </div>

                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='username'
                                        name='username'
                                        type='text'
                                        value={username || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ username: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="username">Имя пользователя *</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'username'}/> }
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            &nbsp;
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')} style={{ marginBottom: '10px', marginTop: '10px' }}>
                            Пароли доступа к площадкам
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='yandex'
                                        name='yandex'
                                        type='text'
                                        value={yandex || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ yandex: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="yandex">Яндекс</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'lkYandexPassword'}/> }
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='imap'
                                        name='imap'
                                        type='text'
                                        value={imap || ''}
                                        onChange={(e) => {
                                            setEntity({ imap: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="imap">IMAP YANDEX пароль</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'imapYandexPassword'}/> }
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='otp'
                                        name='otp'
                                        type='text'
                                        value={otp || ''}
                                        onChange={(e) => {
                                            setEntity({ otp: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="otp">OTP Auth (из приложения)</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'otp'}/> }
                            </div>
                        </div>

                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='gis'
                                        name='gis'
                                        type='text'
                                        value={gis || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ gis: e.target.value });
                                        }}

                                    />
                                    <label htmlFor="gis">2GIS</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'lk2GisPassword'}/> }
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='flamp'
                                        name='flamp'
                                        type='text'
                                        value={flamp || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ flamp: e.target.value });
                                        }}

                                    />
                                    <label htmlFor="flamp">Flamp</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'lkFlampPassword'}/> }
                            </div>
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <OkInputText
                                        id='zoon'
                                        name='zoon'
                                        type='text'
                                        value={zoon || ''}
                                        isrequired="true"
                                        onChange={(e) => {
                                            setEntity({ zoon: e.target.value });
                                        }}
                                    />
                                    <label htmlFor="zoon">Zoon</label>
                                </span>
                                {errors.length > 0 && <FieldMessageError errors={errors} code={'lkZoonPassword'}/> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ParsingAccountsNow.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    parsingAccountsStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'parsingAccountsStore')(observer(ParsingAccountsNow));
