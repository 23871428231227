import moment from "moment";

import AdPlatformTypeObj from "./AdPlatformTypeObj";
import AdPlatformCounters from "./AdPlatformCounters";
import AdPlatformParser from "./AdPlatformParser";
import AdPlatformPublication from "./AdPlatformPublication";
import AdPlatformReview from "./AdPlatformReview";

/**
 * @typedef {string} AdPlatformType
 **/

/**
 * @readonly
 * @enum {AdPlatformType}
 */
export const AD_PLATFORM_TYPE = {
    AVITO: 'avito',
    GOOGLE: 'google',
    YANDEX: 'yandex',
    DGIS: '2gis',
    OTZOVIK: 'otzovik',
    FLAMP: 'flamp',
    IRECOMMEND: 'irecommend',
    ZOON: 'zoon',
    VK: 'vkontakte',
    PRODOCTOROV: 'prodoctorov',
    DOCDOC: 'docdoc',
    NAPOPRAVKU: 'napopravku',
    YELL: 'yell',

    /**
     * @param {AdPlatformType} type
     * @return {string}
     */
    getTitle: (type) => {
        switch (type) {
            case AD_PLATFORM_TYPE.VK:
                return "ВКонтакте";

            case AD_PLATFORM_TYPE.AVITO:
                return "Avito";

            case AD_PLATFORM_TYPE.GOOGLE:
                return "Google";

            case AD_PLATFORM_TYPE.YANDEX:
                return "Яндекс";

            case AD_PLATFORM_TYPE.DGIS:
                return "2ГИС";

            case AD_PLATFORM_TYPE.OTZOVIK:
                return "Отзовик";

            case AD_PLATFORM_TYPE.FLAMP:
                return "Фламп";

            case AD_PLATFORM_TYPE.IRECOMMEND:
                return "Irecommend.ru";

            case AD_PLATFORM_TYPE.ZOON:
                return "Zoon";

            case AD_PLATFORM_TYPE.PRODOCTOROV:
                return "ПроДокторов";

            case AD_PLATFORM_TYPE.DOCDOC:
                return "DocDoc";

            case AD_PLATFORM_TYPE.NAPOPRAVKU:
                return "НаПоправку";

            case AD_PLATFORM_TYPE.YELL:
                return "Yell";

        }

        return type;
    },

    /**
     * @returns {Array<{code: AdPlatformType, title: string}>}
     */
    getAll: () => {
        return [
            {
                code: AD_PLATFORM_TYPE.AVITO,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.AVITO),
            },
            {
                code: AD_PLATFORM_TYPE.GOOGLE,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.GOOGLE),
            },
            {
                code: AD_PLATFORM_TYPE.YANDEX,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.YANDEX),
            },
            {
                code: AD_PLATFORM_TYPE.DGIS,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.DGIS),
            },
            {
                code: AD_PLATFORM_TYPE.OTZOVIK,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.OTZOVIK),
            },
            {
                code: AD_PLATFORM_TYPE.FLAMP,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.FLAMP),
            },
            {
                code: AD_PLATFORM_TYPE.IRECOMMEND,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.IRECOMMEND),
            },
            {
                code: AD_PLATFORM_TYPE.ZOON,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.ZOON),
            },
            {
                code: AD_PLATFORM_TYPE.VK,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.VK),
            },
            {
                code: AD_PLATFORM_TYPE.PRODOCTOROV,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.PRODOCTOROV),
            },
            {
                code: AD_PLATFORM_TYPE.DOCDOC,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.DOCDOC),
            },
            {
                code: AD_PLATFORM_TYPE.NAPOPRAVKU,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.NAPOPRAVKU),
            },
            {
                code: AD_PLATFORM_TYPE.YELL,
                title: AD_PLATFORM_TYPE.getTitle(AD_PLATFORM_TYPE.YELL),
            },


        ];
    },
};

export default class AdPlatform {
    #id;
    #type;
    #title;
    #accountUrl;
    #enabled;
    #createdAt;
    #updatedAt;
    #counters;
    #parser;
    #publication;
    #review;

    constructor(adPlatform) {
        this.#id = adPlatform.id || '';
        this.#type = new AdPlatformTypeObj(adPlatform.type || {});
        this.#title = adPlatform.title || '';
        this.#accountUrl = adPlatform.accountUrl || '';
        this.#enabled = adPlatform.enabled || false;
        this.#createdAt = adPlatform.createdAt || null;
        this.#updatedAt = adPlatform.updatedAt || null;
        this.#counters = new AdPlatformCounters(adPlatform.counters || {});
        this.#parser = new AdPlatformParser(adPlatform.parser || {});
        this.#publication = new AdPlatformPublication(adPlatform.publication || {});
        this.#review = new AdPlatformReview(adPlatform.review || {});
    }

    /**
     * @return {int|string}
     */
    get id() {
        return this.#id;
    }

    /**
     * @return {AdPlatformTypeObj}
     */
    get type() {
        return this.#type;
    }

    /**
     * @return {string}
     */
    get title() {
        return this.#title;
    }

    /**
     * @return {string}
     */
    get accountUrl() {
        return this.#accountUrl;
    }

    /**
     * @return {boolean}
     */
    get enabled() {
        return this.#enabled;
    }

    /**
     * @return {string}
     */
    get createdAt() {
        return this.#createdAt ? moment(this.#createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get updatedAt() {
        return this.#updatedAt ? moment(this.#updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {AdPlatformCounters}
     */
    get counters() {
        return this.#counters;
    }

    /**
     * @returns {AdPlatformParser}
     */
    get parser() {
        return this.#parser;
    }

    /**
     * @returns {AdPlatformPublication}
     */
    get publication() {
        return this.#publication;
    }

    /**
     * @returns {AdPlatformReview}
     */
    get review() {
        return this.#review;
    }

    /**
     * @return {{}}
     */
    getPlainObject() {
        return {
            id: this.id,
            type: this.type,
            title: this.title,
            accountUrl: this.accountUrl,
            enabled: this.enabled,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            counters: this.counters,
            parser: this.parser.getPlainObject(),
            publication: this.publication.getPlainObject(),
            review: this.review.getPlainObject(),
        };
    }

    /**
     * @return {AdPlatform}
     */
    static getEmptyEntity() {
        return new AdPlatform({});
    }
}
