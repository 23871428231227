class ParsingAccountsModel {
    id
    title
    username
    yandex
    otp
    gis
    flamp
    zoon
    imap
    enabled

    constructor(object = {}) {
        this.id = object?.id || null;
        this.title = object?.title || '';
        this.username = object?.username || '';
        this.yandex = object?.lkYandexPassword || '';
        this.otp = object?.otpauthYandexUri || '';
        this.gis = object?.lk2GisPassword || '';
        this.flamp = object?.lkFlampPassword || '';
        this.zoon = object?.lkZoonPassword || '';
        this.imap = object?.imapYandexPassword || '';
        this.enabled = true
    }

    getApiObject(object) {
        return {
            "id": object.id,
            "title": object.title,
            "username": object.username,
            "lkYandexPassword": object.yandex,
            "lk2GisPassword": object.gis,
            "lkFlampPassword": object.flamp,
            "lkZoonPassword": object.zoon,
            "imapYandexPassword": object.imap,
            "otpauthYandexUri": object.otp,
        }
    }
}

export default ParsingAccountsModel