import { makeObservable, observable, action, toJS } from "mobx";
import {
    integrationService,
    branchService,
} from "../services";
import uiStore from "./UiStore";
import { AdPlatform } from "../models";
import { setLinkedById, unsetLinkedById } from "helper";
import { AdPlatformConnectionStatus } from "models/AdPlatformConnectionStatus";
import CommonPageStore from "stores/CommonPageStore";


class AdPlatformStore extends CommonPageStore {
    /**
     * @type {Array<AdPlatform>}
     */
    items = [];

    itemsBranches = {};

    query = '';

    fType = '';

    clientId = '';

    fParserAuthStatus = -1;

    fParserReviews = -1;
    fParserGeodata = -1;


    // noinspection JSValidateTypes
    /**
     * @type {AdPlatform}
     */
    entity = AdPlatform.getEmptyEntity().getPlainObject();

    /**
     * @type {AdPlatformConnectionStatus}
     */
    connectionStatus = AdPlatformConnectionStatus.getEmpty().getPlainObject();

    unsavedConnectionEnabled = null;

    unsavedParsingEnabled = null;

    /**
     * @type {SmallBranch[]}
     */
    branches = [];

    /**
     * @type {BranchAdPlatformLink[]}
     */
    editableBranches = [];

    /**
     * @type {int[]}
     */
    branchesUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    branchesUnsavedDelLinks = [];

    /**
     * @type {BranchAdPlatformLink[]}
     */
    modalBranches = [];

    /**
     * @type {int[]}
     */
    modalBranchesUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    modalBranchesUnsavedDelLinks = [];

    /**
     * @type {Branch[]}
     */
    addBranches = [];

    /**
     * @type {int[]}
     */
    branchesAddUnsavedLinks = [];

    constructor() {
        super();

        makeObservable(
            this,
            {
                query: observable,
                items: observable,
                itemsBranches: observable,
                fType: observable,
                clientId: observable,
                fParserAuthStatus: observable,
                entity: observable,
                connectionStatus: observable,
                branches: observable,
                editableBranches: observable,
                modalBranches: observable,
                addBranches: observable,
                fParserReviews: observable,
                fParserGeodata: observable,

                setQuery: action,
                setFType: action,
                setClientId: action,
                setItems: action,
                setItemsBranches: action,
                setFParserAuthStatus: action,
                setEntity: action,
                setBranchLinked: action,
                delBranchLinked: action,
                saveEntity: action,
                setModalBranchLinked: action,
                delModalBranchLinked: action,
                resetEntity: action,
                setAddBranchLinked: action,
                delAddBranchLinked: action,
                enableConnection: action,
                enableParsing: action,
                refreshConnectionStatus: action,
                setConnectionStatus: action,
                setFParserReviews: action,
                setFParserGeodata: action,

            }
        )
    }


    setQuery = (query) => {
        this.query = query;
    }

    setFParserReviews = (fParserReviews) => {
        this.fParserReviews = fParserReviews;
    }

    setFParserGeodata = (fParserGeodata) => {
        this.fParserGeodata = fParserGeodata;
    }


    /**
     * @param {AdPlatform[]} items
     */
    setItems = (items) => {
        this.items = items;
    }

    /**
     * @param itemsBranches
     */
    setItemsBranches = (itemsBranches) => {
        this.itemsBranches = itemsBranches;
    }

    /**
     * @param {string} typeFilter
     */
    setFType = (typeFilter) => {
        this.fType = typeFilter;
    }

    setClientId = (clientIdFilter) => {
        this.clientId = clientIdFilter;
    }


    /**
     * @param {number} authStatusFilter
     */
    setFParserAuthStatus = (authStatusFilter) => {
        this.fParserAuthStatus = authStatusFilter;
    }

    /**
     * @param {{}} entity
     */
    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        };
    }

    /**
     * @param {int} branchId
     */
    setBranchLinked = (branchId) => {
        this.branchesUnsavedDelLinks = setLinkedById(
            branchId,
            this.branchesUnsavedLinks,
            this.branchesUnsavedDelLinks,
        );
    }

    /**
     * @param {int} branchId
     */
    delBranchLinked = (branchId) => {
        this.branchesUnsavedLinks = unsetLinkedById(
            branchId,
            this.branchesUnsavedLinks,
            this.branchesUnsavedDelLinks,
        );
    }

    setModalBranchLinked = (branchId) => {
        this.modalBranchesUnsavedDelLinks = setLinkedById(
            branchId,
            this.modalBranchesUnsavedLinks,
            this.modalBranchesUnsavedDelLinks,
        );
    }

    delModalBranchLinked = (branchId) => {
        this.modalBranchesUnsavedLinks = unsetLinkedById(
            branchId,
            this.modalBranchesUnsavedLinks,
            this.modalBranchesUnsavedDelLinks,
        );
    }

    saveEntity = () => {
        uiStore.startLoading();

        const branchesLink = [];
        const branchesUnlink = [];

        this.branchesUnsavedLinks.forEach((branchId) => {
            if (!this.branchLinkExists(branchId)) {
                branchesLink.push(branchId);
            }
        });
        this.branchesUnsavedDelLinks.forEach((branchId) => {
            if (this.branchLinkExists(branchId)) {
                branchesUnlink.push(branchId);
            }
        });

        const entityId = this.entity.id;

        return integrationService
            .save(entityId, this.prepareEntityData())
            .then(() => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                );

                this.branchesUnsavedLinks = [];
                this.branchesUnsavedDelLinks = [];

                const linkPromises = [
                    ...branchesLink.map(branchId => integrationService.setLinked(entityId, branchId)),
                    ...branchesUnlink.map(branchId => integrationService.delLinked(entityId, branchId)),
                ];

                if (this.unsavedConnectionEnabled !== null) {
                    if (this.unsavedConnectionEnabled && !this.connectionStatus.isConnectionCreated) {
                        linkPromises.push(integrationService.makeConnection(entityId));
                    }
                }

                if (this.unsavedParsingEnabled !== null) {
                    if (this.unsavedParsingEnabled) {
                        linkPromises.push(integrationService.activateConnection(entityId));
                    } else if (!this.unsavedParsingEnabled) {
                        linkPromises.push(integrationService.deactivateConnection(entityId));
                    }
                }

                if (linkPromises.length) {
                    return Promise.all(linkPromises);
                }

                return Promise.resolve();
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });


                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = () => {
        let filter = {
            page: toJS(this.pager),
            sort: toJS(this.sort),
            clientId: toJS(this.clientId),
            query: toJS(this.query),
            type: toJS(this.fType),
        };

        if(this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        if (this.fParserReviews !== -1) {
            filter = {
                ...filter,
                ...{ parserReviews: toJS(this.fParserReviews) }
            }
        }

        if (this.fParserGeodata !== -1) {
            filter = {
                ...filter,
                ...{ parserGeodata: toJS(this.fParserGeodata) }
            }
        }


        const fParserAuthStatus = toJS(this.fParserAuthStatus);

        if (fParserAuthStatus > -1) {
            filter.parserAuth = fParserAuthStatus
        }

        this.setItemsBranches({});
        uiStore.startLoading();
        integrationService
            .getIntegrations(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setSort(result?.sort);
                this.setPager(result?.pager);

                return Promise.all(
                    result
                        .items
                        .map((adPlatform) => integrationService.findBranches(adPlatform.id, true))
                );
            })
            .then((linkedBranches) => {
                const itemsBranches = {};

                linkedBranches
                    .forEach((linkedBranchesData) => {
                        itemsBranches[linkedBranchesData.adPlatformId] = linkedBranchesData.items;
                    });
                this.setItemsBranches(itemsBranches);
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    /**
     * @param {int} integrationId
     * @param {boolean} isEdit
     */
    loadEntity = (integrationId, isEdit) => {
        this.setEntity(AdPlatform.getEmptyEntity().getPlainObject());
        uiStore.startLoading();
        integrationService
            .getIntegration(integrationId)
            .then((data) => {
                this.setEntity(data.adPlatform.getPlainObject());
                return integrationService.findBranches(integrationId, !isEdit);
            })
            .then((branches) => {
                if (isEdit) {
                    this.branches = [];
                    this.editableBranches = branches.items;
                } else {
                    this.branches = branches.items.map((link) => link.branch);
                    this.editableBranches = [];
                }
            })
            .finally(() => {
                uiStore.stopLoading();
            })
    }

    getParser = (integrationId) => {
        return integrationService.getParser(integrationId).then(result => {
            return result;
        })
    }

    getPublisher = (integrationId) => {
        return integrationService.getPublisher(integrationId).then(result => {
            return result;
        })
    }



    /**
     * @param {int} integrationId
     */
    loadModalBranches = (integrationId) => {
        uiStore.startLoading();
        this.modalBranches = [];
        integrationService
            .findBranches(integrationId, false)
            .then((result) => {
                this.modalBranches = result.items;
            })
            .finally(() => {
                uiStore.stopLoading();
            })
    }

    /**
     * @param {int} integrationId
     * @return {Promise}
     */
    saveModalBranches = (integrationId) => {
        uiStore.startLoading();

        const branchesLink = [];
        const branchesUnlink = [];

        this.modalBranchesUnsavedLinks.forEach((branchId) => {
            if (!this.modalBranchLinkExists(branchId)) {
                branchesLink.push(branchId);
            }
        });
        this.modalBranchesUnsavedDelLinks.forEach((branchId) => {
            if (this.modalBranchLinkExists(branchId)) {
                branchesUnlink.push(branchId);
            }
        });

        this.modalBranchesUnsavedLinks = [];
        this.modalBranchesUnsavedDelLinks = [];

        return Promise
            .all([
                ...branchesLink.map((branchId) => integrationService.setLinked(integrationId, branchId)),
                ...branchesUnlink.map((branchId) => integrationService.delLinked(integrationId, branchId)),
            ])
            .finally(() => {
                uiStore.stopLoading();
            })
    }

    resetEntity = () => {
        this.setEntity(AdPlatform.getEmptyEntity().getPlainObject());
        this.setConnectionStatus(AdPlatformConnectionStatus.getEmpty().getPlainObject());
        this.unsavedConnectionEnabled = null;
        this.unsavedParsingEnabled = null;
        return Promise.resolve('OK')
    }

    /**
     * @return {Promise<unknown>}
     */
    addEntity = () => {
        uiStore.startLoading();

        return integrationService
            .add(this.prepareEntityData())
            .then(result => {

                if (result) {
                    this.setEntity(result.adPlatform.getPlainObject());
                    if (this.branchesAddUnsavedLinks.length) {
                        this.branchesAddUnsavedLinks = [];
                        return Promise
                            .all(
                                this
                                    .branchesAddUnsavedLinks
                                    .map((branchId) => integrationService.setLinked(this.entity.id, branchId))
                            );
                    }
                    return Promise.resolve(true);
                } else {
                    return Promise.reject();
                }
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );

                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });

                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    refreshConnectionStatus = (adPlatformId) => {
        uiStore.startLoading();

        return integrationService
            .refreshConnectionStatus(adPlatformId)
            .then((status) => {
                this.setConnectionStatus(status.getPlainObject());
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );

                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    };

    setConnectionStatus = (connectionStatus) => {
        this.connectionStatus = connectionStatus;
    }

    /**
     * @param {int} clientId
     */
    loadAddBranches = (clientId) => {
        uiStore.startLoading();
        branchService
            .getBranches(
                clientId,
                {
                    page: {
                        size: 100,
                    }
                }
            )
            .then((result) => {
                // noinspection JSValidateTypes
                this.addBranches = result.items.map(branch => branch.getPlainObject());
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    /**
     * @param {int} branchId
     */
    setAddBranchLinked = (branchId) => {
        setLinkedById(branchId, this.branchesAddUnsavedLinks, []);
    }

    /**
     * @param {int} branchId
     */
    delAddBranchLinked = (branchId) => {
        this.branchesAddUnsavedLinks = unsetLinkedById(branchId, this.branchesAddUnsavedLinks, []);
    }

    /**
     * @param isEnabled
     */
    enableConnection = (isEnabled) => {
        this.unsavedConnectionEnabled = isEnabled;
    }

    /**
     * @param isEnabled
     */
    enableParsing = (isEnabled) => {
        this.unsavedParsingEnabled = isEnabled;
    }

    /**
     * @param {int} branchId
     * @return {boolean}
     * @private
     */
    branchLinkExists = (branchId) => {
        return this
            .editableBranches
            .findIndex((item) => item.branch.id === branchId && item.linked) > -1;
    }

    /**
     * @param {int} branchId
     * @return {boolean}
     * @private
     */
    modalBranchLinkExists = (branchId) => {
        return this
            .modalBranches
            .findIndex((item) => item.branch.id === branchId && item.linked) > -1;
    }

    /**
     * @returns {{}}
     * @private
     */
    prepareEntityData = () => {
        const obj = {
            type: this.entity.type.code,
            title: this.entity.title,
            accountUrl: this.entity.accountUrl,
            enabled: this.entity.enabled,
        };

        // noinspection JSUnresolvedVariable
        if (this.entity.clientId) {
            obj.clientId = this.entity.clientId;
        }

        return obj;
    }
}

const adPlatformStore = new AdPlatformStore();
export default adPlatformStore;
